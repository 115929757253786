import React from 'react';
import { graphql } from 'gatsby';

import {
  ContentfulNews,
  ContentfulSettings,
  ContentfulNewsListingSection,
  ContentfulNewsConnection,
  ContentfulCookieBanner,
  Site,
} from '../generated/gatsby.types';
import Layout from '../components/layout';
import extractShortDescription, { ItemType } from '../transforms/ShortDescription';
import transformNewsListingSection from '../transforms/NewsListing';
import transformProductsServicesSection from '../transforms/ProductsServices';
import extractFileInforamtion from '../transforms/File';
import richTextRawToJson from '../utils/contentful-rich-text-raw-to-json';
import { NewsPage } from '../ui-lib/components';
import { NewsListingModuleProps } from '../ui-lib/components/Modules/NewsListingModule';

type NewsQueryType = {
  contentfulNews: ContentfulNews;
  contentfulCookieBanner: ContentfulCookieBanner;
  site: Site;
  contentfulSettings: ContentfulSettings;
  contentfulNewsListingSection: ContentfulNewsListingSection;
  allContentfulNews: ContentfulNewsConnection;
  translatedSlugs: ContentfulNewsConnection;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

type NewsProps = {
  data: NewsQueryType;
  pageContext: PageContextType;
};

const News = ({ data, pageContext }: NewsProps) => {
  const {
    title,
    date,
    shortDescription,
    contentsSection,
    image,
    relatedProducts: featuredProducts,
    document,
    updatedAt,
  } = data.contentfulNews;

  return (
    <Layout
      pageTitle={title as string}
      pageDescription={extractShortDescription(shortDescription as any)}
      pageImage={image || undefined}
      pageLocale={pageContext.node_locale}
      pageType="news"
      siteUrl={data.site.siteMetadata.siteUrl}
      pageSlug={data.contentfulNews.slug ? data.contentfulNews.slug : ''}
      contentfulSettings={data.contentfulSettings}
      pageSettings={data.contentfulNews.metaTags}
      cookieBannerTitle={data.contentfulCookieBanner?.title}
      cookieBannerDescription={data.contentfulCookieBanner?.description}
      updatedAt={updatedAt}
      swiftTypeDate={data.contentfulNews.date ? data.contentfulNews?.date : undefined}
      ogPageType="article"
      showFooterSubscribeForm={data.contentfulSettings.footerShowSubscribeForm || false}
      subscribeFormHeading={data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading}
      flexmailId={data.contentfulSettings.flexmailId}
      translatedSlugs={data.translatedSlugs.nodes}
    >
      <NewsPage
        title={title as string}
        shortDescription={shortDescription ? extractShortDescription(shortDescription as any) : undefined}
        date={date}
        contentsSection={{
          data: contentsSection ? richTextRawToJson(contentsSection, pageContext.node_locale).json : null,
        }}
        image={image}
        newsSection={{
          ...(transformNewsListingSection(
            data.contentfulNewsListingSection as ItemType,
            data.allContentfulNews,
          ) as NewsListingModuleProps),
          initialAmount: 2,
          incrementAmount: 6,
          variant: 'secondary',
        }}
        featuredProducts={featuredProducts ? transformProductsServicesSection(featuredProducts) : null}
        documentDownload={extractFileInforamtion(document)}
      />
    </Layout>
  );
};

export default News;

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!, $brand_tag: String!, $contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulCookieBanner(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      title
      description {
        raw
      }
    }
    translatedSlugs: allContentfulNews(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        slug
        node_locale
      }
    }
    contentfulNews(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      seoMetadata {
        id
        description
        legacyDescription {
          legacyDescription
        }
        keywords
        no_index
        no_follow
        canonicalUrlpath
        title
      }
      # TODO migrate to seoMetadata
      metaTags {
        id
        description
        nofollow
        title
        keywords
        canonical
        noindex
      }
      image {
        title
        alt: altText
        asset {
          # fluid(quality: 100) {
          #   ...QueryImageFluid
          # }
          gatsbyImageData(layout: FULL_WIDTH, quality: 85)
        }
        socialImage: asset {
          # fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
          #   ...QueryImageFixed
          # }
          gatsbyImageData(layout: FIXED, width: 1200, height: 630, quality: 100, resizingBehavior: CROP)
        }
      }
      date(formatString: "D, MMMM Y")
      shortDescription {
        text: shortDescription
      }
      contentsSection {
        raw
        references {
          __typename

          ... on ContentfulAdvice {
            contentful_id
            slug
          }

          ... on ContentfulBasicPage {
            contentful_id
            slug
          }

          ... on ContentfulEvent {
            contentful_id
            slug
          }

          ... on ContentfulNews {
            contentful_id
            slug
          }

          ... on ContentfulProduct {
            contentful_id
            slug
          }

          ... on ContentfulAsset {
            contentful_id
            file {
              url
            }
          }

          ... on ContentfulAttachment {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            asset {
              file {
                url
                details {
                  size
                }
                contentType
              }
            }
          }

          ... on ContentfulBlockquote {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            quote {
              text: quote
            }
            author
            authorInfo
            image {
              title
              alt: altText
              asset {
                file {
                  url
                }
                gatsbyImageData
              }
            }
          }

          ... on ContentfulImage {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            alt: altText
            asset {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              gatsbyImageData
            }
          }

          ... on ContentfulMediaBlock {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            shortDescription {
              text: shortDescription
            }
            caption {
              text: caption
            }
            orientation
            media {
              ... on ContentfulImage {
                __typename
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                alt: altText
                asset {
                  file {
                    url
                  }
                  portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                  squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                  landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                }
              }

              ... on ContentfulVideo {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                videoUrl
              }
            }
          }

          ... on ContentfulShortBanner {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            image {
              title
              alt: altText
              asset {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
                gatsbyImageData
              }
            }
            cta {
              title
              internalEntry {
                __typename
                ... on ContentfulAdvice {
                  contentful_id
                  slug
                }

                ... on ContentfulBasicPage {
                  contentful_id
                  slug
                }

                ... on ContentfulEvent {
                  contentful_id
                  slug
                }

                ... on ContentfulNews {
                  contentful_id
                  slug
                }

                ... on ContentfulProduct {
                  contentful_id
                  slug
                }
              }
              externalUrl
            }
          }

          ... on ContentfulTextSectionHighlight {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            richTextContents {
              raw
              references {
                __typename
                ... on ContentfulAdvice {
                  contentful_id
                  slug
                }

                ... on ContentfulBasicPage {
                  contentful_id
                  slug
                }

                ... on ContentfulEvent {
                  contentful_id
                  slug
                }

                ... on ContentfulNews {
                  contentful_id
                  slug
                }

                ... on ContentfulProduct {
                  contentful_id
                  slug
                }

                ... on ContentfulAsset {
                  contentful_id
                  file {
                    url
                  }
                }
              }
            }
          }

          ... on ContentfulVideo {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            videoUrl
          }

          ... on ContentfulRichTextSection {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            alignment
            richText {
              raw
              references {
                __typename
                ... on ContentfulAdvice {
                  contentful_id
                  slug
                }

                ... on ContentfulBasicPage {
                  contentful_id
                  slug
                }

                ... on ContentfulEvent {
                  contentful_id
                  slug
                }

                ... on ContentfulNews {
                  contentful_id
                  slug
                }

                ... on ContentfulProduct {
                  contentful_id
                  slug
                }

                ... on ContentfulAsset {
                  contentful_id
                  file {
                    url
                  }
                }

                ... on ContentfulAttachment {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  asset {
                    file {
                      url
                      details {
                        size
                      }
                      contentType
                    }
                  }
                }

                ... on ContentfulBlockquote {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  quote {
                    text: quote
                  }
                  author
                  authorInfo
                  image {
                    title
                    alt: altText
                    asset {
                      file {
                        url
                      }
                      gatsbyImageData
                    }
                  }
                }

                ... on ContentfulImage {
                  contentful_id

                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  alt: altText
                  asset {
                    file {
                      url
                      details {
                        image {
                          width
                          height
                        }
                      }
                    }
                    gatsbyImageData
                  }
                }

                ... on ContentfulMediaBlock {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  shortDescription {
                    text: shortDescription
                  }
                  caption {
                    text: caption
                  }
                  orientation
                  media {
                    ... on ContentfulImage {
                      __typename
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      alt: altText
                      asset {
                        file {
                          url
                        }
                        portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                        squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                        landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                      }
                    }

                    ... on ContentfulVideo {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      videoUrl
                    }
                  }
                }

                ... on ContentfulShortBanner {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  image {
                    title
                    alt: altText
                    asset {
                      file {
                        url
                        details {
                          image {
                            width
                            height
                          }
                        }
                      }
                      gatsbyImageData
                    }
                  }
                  cta {
                    title
                    internalEntry {
                      __typename
                      ... on ContentfulAdvice {
                        contentful_id
                        slug
                      }

                      ... on ContentfulBasicPage {
                        contentful_id
                        slug
                      }

                      ... on ContentfulEvent {
                        contentful_id
                        slug
                      }

                      ... on ContentfulNews {
                        contentful_id
                        slug
                      }

                      ... on ContentfulProduct {
                        contentful_id
                        slug
                      }
                    }
                    externalUrl
                  }
                }

                ... on ContentfulTable {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  table {
                    tableData
                  }
                  fixFirstColumn
                }

                ... on ContentfulTextSectionHighlight {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  richTextContents {
                    raw
                    references {
                      __typename
                      ... on ContentfulAdvice {
                        contentful_id
                        slug
                      }

                      ... on ContentfulBasicPage {
                        contentful_id
                        slug
                      }

                      ... on ContentfulEvent {
                        contentful_id
                        slug
                      }

                      ... on ContentfulNews {
                        contentful_id
                        slug
                      }

                      ... on ContentfulProduct {
                        contentful_id
                        slug
                      }

                      ... on ContentfulAsset {
                        contentful_id
                        file {
                          url
                        }
                      }
                    }
                  }
                }

                ... on ContentfulVideo {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  videoUrl
                }
              }
            }
          }
        }
      }
      document {
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      relatedProducts {
        title
        shortDescription {
          text: shortDescription
        }
        products {
          slug
          title
          shortDescription {
            text: shortDescription
          }
          price
          image {
            title
            alt: altText
            asset {
              # fluid {
              #   ...QueryImageFluid
              # }
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          id
        }
        cta {
          externalUrl
          internalEntry {
            ...FragmentNavigationLink
          }
          title
        }
      }
    }

    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }

    contentfulNewsListingSection(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QueryNewsListing
    }

    allContentfulNews(
      filter: {
        node_locale: { eq: $node_locale }
        id: { ne: $id }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          updatedAt
          title
          slug
          id
          date
          shortDescription {
            text: shortDescription
          }
          image {
            title
            alt: altText
            asset {
              # fluid(resizingBehavior: FILL, quality: 85) {
              #   ...QueryImageFluid
              # }
              gatsbyImageData(layout: CONSTRAINED, resizingBehavior: FILL, quality: 85)
            }
          }
        }
      }
    }
  }
`;
